/**
 * Color
 */
$font-black: #000000;
$font-gray: #707070;
$border: #e2e2e2;
$white: #ffffff;
$campfire-red: #E13A43;
$campfire-light-red: #FFF4F4;
$polca-yellow: #FFCC00;
$polca-light-yellow: #FFFAE5;
$background-grey: #f7f7f7;

/**
 * Layout
 */
$container-width: 1100px;
$sp-break-point: $container-width;

@mixin shadow() {
  box-shadow: 0 6px 6px rgba($color: $font-black, $alpha: 0.05)
}