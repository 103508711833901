@import './stylesheets/settings';
@import './stylesheets/base';
@import './stylesheets/utilities';

.GlobalHeader {
  padding: 2rem;

  @media (max-width: $sp-break-point) {
    padding: 2rem 1rem;
  }
}

.NavigationItemList {
  @media (max-width: $sp-break-point) {
    display: none;
  }

  .NavigationItem {
    margin-left: 1rem;
  }

  .GlobalHeaderContactButton {
    margin-left: 1.5rem;
  }
}

.NavigationItem {
  list-style-type: none;

  &__link {
    color: $font-gray;
    font-weight: bold;
  }
}

.Logo {
  .LogoImage {
    height: 3rem;
  }
}

.LogoImage {

}

.GlobalHeaderContactButton {
 color: $white;
  font-weight: bold;
  // background: linear-gradient(45deg, $campfire-red 20%, $polca-yellow);
  background-color: $campfire-red;
  padding: .8rem 2rem;
  border-radius: 4px;
  display: inline-block;
  text-align: center;
}

.Hero {
  background-image: url('./images/20@2x.png');
  background-size: 100%;
  background-position: top 0px left -55px;
  background-repeat: no-repeat;
  padding-bottom: 5rem;

  @media (max-width: $sp-break-point) {
    background-size: 130%;
  }

  @media (max-width: 650px) {
    background-size: 150%;
  }

  @media (max-width: 450px) {
  background-position: top 0px left -85px;
    background-size: 240%;
  }

  .HeroDescription {
    margin: 1.5rem 0;
  }
}

.Sponsored {
  padding: 2rem !important;
  h1 {
    font-size: 1.2rem;
    color: $font-black;
    width: 100%;
    margin-bottom: 1rem;
  }

  h2 {
    font-size: 1rem;
    color: $font-gray;
  }
}

.SponsoredItemList {
  padding: 1rem 0;

  .SponsoredItem {
    margin-right: 2rem;
  }
}

.Sponsor:first-child {
  margin-right: 3rem
}

.SponsoredItem {
  width: 100px;
  display: block;

  img {
    width: 100%;
    height: 4rem;
    object-fit: contain;
  }
}

.HeroCatchcopy {
  font-size: 3.75rem;
  font-weight: bold;
  color: $font-black;
  width: 100%;
  line-height: 1.4;
  padding-top: 8rem;

  @media (max-width: $sp-break-point) {
    font-size: 2.5rem;
    padding-top: 5rem;
  }
}

.HeroDescription {
  font-size: 2.5rem;
  font-weight: bold;
  color: $font-gray;
  width: 100%;
  line-height: 1.4;

  @media (max-width: $sp-break-point) {
    font-size: 1.5rem;
    line-height: 1.6;
  }

  &__negativeMargin {
    margin-left: -1.2rem;
  }
}

.Section {
  padding: 5rem 0;

  &--red {
    background-color: $campfire-light-red;
  }

  &--grey {
    background-color: $background-grey;
  }
}

.SectionTitle {
  color: $campfire-red;
  margin-bottom: 1.5rem;
  line-height: 1.4;
}

.SectionDescription {
  line-height: 1.4;
  color: $font-gray;

  @media (max-width: $sp-break-point) {
    line-height: 1.6;
  }
}

.ProgramItemList {
  margin-top: 4rem;

  .ProgramItem {
    margin-bottom: 2rem;
    box-sizing: border-box;
  }
}

.ProgramItem {
  @include shadow();
  width: 100%;
  border: 1px solid $campfire-red;
  padding: 2rem;

  .ProgramItemTitle {
    margin-bottom: 1.5rem;
  }

  .ProgramItemButton {
    margin: 1.5rem 0;
  }
}

.ProgramItemTitle {
  font-size: 1.5rem;
  color: $font-gray;
  font-weight: bold;

  &--negativeMargin {
    margin-left: -0.9rem;

    @media (max-width: $sp-break-point) {
      margin-left: 0;
    }
  }
}

.ProgramItemDescription {
  font-size: 1rem;
  line-height: 1.4;
  color: $font-gray;
}

.ProgramItemButton {
  color: $white;
  font-weight: bold;
  background: linear-gradient(45deg, $campfire-red 20%, $polca-yellow);
  padding: .8rem 2rem;
  border-radius: 4px;
  display: inline-block;
  text-align: center;

  @media (max-width: $sp-break-point) {
    width: 100%;
    box-sizing: border-box;
  }
}

.ProgramItemFee {
  color: $font-gray;

  @media (max-width: $sp-break-point) {
    font-size: .8rem;
  }
}

.ProgramItemFeeRate {
  font-size: 2rem;
  font-weight: bold;
  margin: 0 .4rem;

  @media (max-width: $sp-break-point) {
    font-size: 1.5rem;
  }
}

.BrandLogo {
  margin: 4rem 0 3rem;
}

.BrandLogoImage {
  height: 3rem;

  &--cf {
    margin-left: -1.05rem;
    height: 3.5rem;
  }

  &--polca {
    height: 2rem;
    margin-right: 1rem;
  }
}

.CampfireInterviewItemList {
  margin-bottom: 7rem;

  .CampfireInterviewItem {
    margin-bottom: 3rem;
  }
}

.CampfireInterviewItem {
  @media (max-width: $sp-break-point) {
    flex-wrap: wrap;
  }
}

.CampfireInterviewItemProjectInfo {
  width: 420px;

  @media (max-width: $sp-break-point) {
    width: 100%;
  }

  .CampfireInterviewItemProjectTitle {
    margin: 1rem 0;
  }
}

.CampfireInterviewItemProjectImage {
  width: 100%;
}

.CampfireInterviewItemProjectTitle {
  font-size: 1.25rem;
  font-weight: bold;

  &__link {
    color: $font-gray;
  }
}

.CampfireInterviewItemProjectAuthor {
  .CampfireInterviewItemProjectAuthorImage {
    margin-right: .5rem;
  }
}

.CampfireInterviewItemProjectAuthorImage {
  width: 2rem;
  height: 2rem;
  object-fit: cover;
  border-radius: 1rem;
  border: 1px solid $border;
}

.CampfireInterviewItemProjectAuthorName {
  &__link {
    color: $font-gray;
    line-height: 2rem;
  }
}

.CampfireInterviewItemContent {
  width: 640px;

  @media (max-width: $sp-break-point) {
    margin-top: 2rem;
    width: 100%;
  }


  .CampfireInterviewItemContentHeader {
    margin-bottom: .5rem;
  }

  .CampfireInterviewItemContentDescription {
    margin-left: 3rem;
    margin-bottom: 1rem;
  }
}

.CampfireInterviewItemContentHeader {
  font-size: 1.25rem;
  font-weight: bold;
  color: $font-gray;
  line-height: 2rem;

  .CampfireInterviewItemContentHeaderCount {
    margin-right: 1rem;
    flex-shrink: 0;
  }
}

.CampfireInterviewItemContentHeaderCount {
  display: block;
  background-color: $campfire-red;
  color: $white;
  line-height: 2rem;
  width: 2rem;
  height: 2rem;
  font-size: 1rem;
  border-radius: 1rem;
  font-weight: bold;
  text-align: center;

  &--first {
    background-color: $campfire-red;
  }

  &--second {
    background-color: #EB7C3C;
  }

  &--third {
    background-color: $polca-yellow
  }
}

.CampfireInterviewItemContentDescription {
  font-size: .875rem;
  line-height: 1.4;
  color: $font-gray;
}

.PolcaInterviewItemList {
  width: 100%;
  background-color: #fff;
  column-count: 3;

  @media (max-width: $sp-break-point) {
    column-count: 2;
  }

  @media (max-width: 700px) {
    column-count: 1;
  }

  .PolcaInterviewItem {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
    margin-bottom: 2rem;
  }
}

.PolcaInterviewItem {
  width: 330px;

  @media (max-width: 700px) {
    width: 100%;
  }

  .PolcaInterviewItemAuthor {
    margin-bottom: .5rem;
  }

  .PolcaInterviewItemTitle {
    margin-bottom: 1rem
  }
}

.PolcaInterviewItemAuthor {
  .PolcaInterviewItemAuthorImage {
    margin-right: .5rem;
  }
}

.PolcaInterviewItemAuthorImage {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: .8rem;
  border: 2px solid $polca-yellow;
}

.PolcaInterviewItemAuthorName {
  font-size: .75rem;
  line-height: 1.25rem;

  &__link {
    color: $font-gray;
  }
}

.PolcaInterviewItemTitle {
  font-size: 1.25rem;
  font-weight: bold;
  color: $font-gray;

  &__link {
    line-height: 1.6;
    color: inherit;
  }
}

.PolcaInterviewItemComment {
  font-size: .875rem;
  color: $font-gray;
  background-color: $polca-light-yellow;
  padding: .7rem 1rem;

  .PolcaInterviewItemCommentHeader {
    margin-bottom: .5rem;
  }
}

.PolcaInterviewItemCommentHeader {
  font-size: .625rem;
  color: $font-gray;
}

.PolcaInterviewItemCommentContent {
  font-size: .875rem;
  color: $font-gray;
}

.GlobalFooter {
  background-color: $campfire-red;
}

.Coryright {
  color: $white;
  font-weight: bold;
  font-size: .875rem;
  text-align: center;
  line-height: 4rem;
}

.GlobalFooterContact {
  background-color: $campfire-light-red;

  padding: 2rem 0;

  .GlobalFooterContactButton {
    margin: 1rem 0 0;
  }
}

.GlobalFooterContactDescription {
  text-align: center;
  width: 100%;
  font-weight: bold;
  color: $font-gray;
}

.GlobalFooterContactButton {
  color: $white;
  font-weight: bold;
  background: linear-gradient(45deg, $campfire-red 20%, $polca-yellow);
  padding: .8rem 2rem;
  border-radius: 4px;
  display: inline-block;
  text-align: center;

  @media (max-width: $sp-break-point) {
    width: 100%;
    box-sizing: border-box;
  }
}
