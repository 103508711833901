@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,700&subset=japanese");


body {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 16px;
  margin: 0;
  background-color: $white;
  background-position: center;
  background-size: contain;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

// import some base styles from https://github.com/morishitter/base/blob/master/lib/_base.scss

/**
 * Removes the default spacing and border for appropriate elements.
 */
blockquote,
dl,
dd,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre {
  margin: 0;
}

button {
  background: transparent;
  border: 0;
  padding: 0;
  outline: none;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

iframe {
  border: 0;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

/**
 * Suppress the focus outline on elements that cannot be accessed via keyboard.
 * This prevents an unwanted focus outline from appearing around elements that
 * might still respond to pointer events.
 */

[tabindex="-1"]:focus {
  outline: none !important;
}


