.container {
  width: $container-width;

  @media (max-width: $sp-break-point) {
    width: 100%;
    padding: 0 1rem;
  }
}

.flex {
  display: flex;
}

.spaceBetween {
  justify-content: space-between
}

.center {
  justify-content: center;
}

.verticalCenter {
  align-items: center;
}

.flexEnd {
  justify-content: flex-end
}

.wrap {
  flex-wrap: wrap;
}

